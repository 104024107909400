/* eslint-disable react/no-unescaped-entities */
function FirstTextSection() {
  return (
    <div className="section-text">
      <p>
        Supermercados TA-TA (en adelante TATA), razón social TA-TA Sociedad
        Anónima, constituida en la República Oriental del Uruguay, inscripta en
        el Registro Único Tributario con el número 210003270017, domiciliada en
        José de Béjar 2600, en Montevideo, con número telefónico 25089600,
        gestiona y es titular del sitio web{' '}
        <a href="www.tata.com.uy" className="tata-link">
          www.tata.com.uy
        </a>
      </p>
      <p>
        El presente documento describe los términos y condiciones generales
        aplicables a la utilización, navegación y compra de los productos
        ofrecidos por TATA, en y a través del sitio{' '}
        <a href="www.tata.com.uy" className="tata-link">
          www.tata.com.uy
        </a>
      </p>

      <p>
        Para la funcionalidad y uso del Sitio es esencial el Programa Plus. El
        Cliente declara conocer y aceptar lo antes dicho, así como en su
        totalidad las Bases y Condiciones del Programa Plus que se adjuntan como
        Anexo I y se encuentran disponibles en el link:{' '}
        <a
          href="https://www.plus.uy/bases-y-condiciones."
          className="tata-link"
        >
          https://www.plus.uy/bases-y-condiciones.
        </a>{' '}
        Una vez que el Usuario se registra en{' '}
        <a href="www.tata.com.uy" className="tata-link">
          www.tata.com.uy
        </a>{' '}
        automáticamente queda ingresado al sistema de beneficios plus, siendo a
        partir de entonces "Cliente Plus".
      </p>
      <p>
        Plus es el programa de beneficios de Supermercados Ta-Ta, BAS y Multi
        Ahorro Hogar. Los Clientes podrán obtener a partir de la adquisición de
        productos, “Puntos Canjeables” por cualquier producto de las marcas
        mencionadas y accederán a los Precios Plus diferenciales en todos los
        productos que estén seleccionados en la promoción vigente. Los Puntos
        Canjeables se pueden utilizar en cualquier local físico de Ta-Ta, BAS y
        Multi Ahorro Hogar, pero no pueden utilizarse para canje en los canales
        e-commerce.
      </p>
      <p>
        Se entenderá por Usuario a los visitantes del sitio{' '}
        <a href="www.tata.com.uy" className="tata-link">
          www.tata.com.uy
        </a>
        , ya sea Usuario registrado o simple navegador (en adelante el “Usuario”
        o “Cliente”). El Usuario declara conocer y aceptar los términos y
        condiciones presentes al ingresar al sitio web. En consecuencia, todas
        las visitas y todos los contratos y transacciones que se realicen en
        este sitio, como asimismo sus efectos jurídicos, quedarán regidos por
        estas reglas y sometidas a la normativa de la República Oriental del
        Uruguay en la materia.
      </p>
      <p>
        Los términos y condiciones contenidos en este instrumento se aplicarán y
        se entenderán como formando parte de todos los actos y contratos que se
        ejecuten o celebren mediante los sistemas de oferta y comercialización
        comprendidos en este sitio web entre los Usuarios del mismo.
      </p>
      <p>
        De existir desacuerdo del Usuario en cuanto a los términos y
        condiciones, los que poseen carácter obligatorio y vinculante, deberá
        abstenerse de utilizar el sitio y/o los servicios.
      </p>
      <p>
        El Usuario que ingrese a este sitio web{' '}
        <a href="www.tata.com.uy" className="tata-link">
          www.tata.com.uy
        </a>
        , deberá leer, entender y aceptar los presentes términos y condiciones,
        así como a los demás documentos que se incorporen.
      </p>

      <p>
        Cada vez que el Usuario utilice este sitio significará aceptación plena
        y expresa de los términos y condiciones establecidos, así como de los
        demás documentos que se incorporen al sitio web por TATA, obligándose
        expresamente por el solo uso del mismo.
      </p>
      <p>
        TATA se reserva el derecho de realizar modificaciones a los presentes
        términos y condiciones de uso en el Sitio y en las notificaciones que
        correspondieren, con la simple modificación del presente, y en cualquier
        momento, comunicándolo a través de la publicación en el Sitio.
      </p>
    </div>
  )
}

export default FirstTextSection

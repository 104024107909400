function SixthTextSection() {
  return (
    <>
      <div className="section-title">
        <span className="section-title-text">ANEXO I</span>
        <span className="section-subtitle-text">
          BASES Y CONDICIONES PROGRAMA DE BENEFICIOS PLUS DE TATA, MULTI AHORRO
          HOGAR Y BAS
        </span>
        <span className="section-subtitle-text">PROGRAMA DE FIDELIDAD</span>
      </div>

      <span className="number-item-title">1. OBJETO DEL PROGRAMA</span>
      <div>
        <p>
          1.1. Con el propósito de beneficiar y premiar a nuestros clientes
          TA-TA, MULTI AHORRO HOGAR, y BAS (en adelante la “Empresa”), TA-TA
          S.A. ha creado un Programa de puntos “PLUS”.
        </p>
        <p>
          1.2. Mediante la participación en el programa PLUS, en adelante el
          “Programa” los clientes podrán obtener a partir de la adquisición de
          productos de la Empresa, “Puntos Canjeables” por cualquier producto de
          la Empresa, descuentos, servicios, promociones y beneficios puntuales
          en algunos productos seleccionados por la Empresa, cuando corresponda.
        </p>
        <p>
          1.3. Los Puntos Canjeables obtenidos son para uso personal y no son
          transferibles.
        </p>
      </div>
      <span className="number-item-title">2. USUARIOS</span>
      <div>
        <p>
          2.1. Puede formar parte del programa “PLUS” toda persona física, mayor
          de 18 años, capaz, que haya aceptado las presentes bases y
          condiciones, en adelante “Bases y Condiciones”, en adelante el
          “Usuario”.
        </p>
        <p>
          2.2. El Usuario será responsable de los daños y perjuicios que fueran
          consecuencia de actos u omisiones propios del Usuario.
        </p>
        <p>
          2.3. Datos esenciales para el Programa Plus. Se considerarán datos
          esenciales para el correcto funcionamiento del programa los
          siguientes: Nombre, Apellido, documento de identidad, tipo de
          documento, género con el cual se identifica, fecha de nacimiento,
          email, dirección, departamento, localidad y celular.
        </p>
      </div>
      <span className="number-item-title">3. PROGRAMA PLUS</span>
      <div>
        <p>
          3.1. PLUS es un programa por el cual los Usuarios podrán adquirir –
          entre otros beneficios - Puntos Canjeables mediante sus compras-en
          efectivo, tarjetas de debito, tarjetas de crédito, ticket alimentación
          electrónico y tarjeta Uruguay Social- en cualquier unidad de negocio
          de la Empresa, y canjearlos por cualquier producto disponible en las
          unidades de negocio de la Empresa.
        </p>
        <p>
          3.2. El Usuario será responsable frente a la Empresa y cualquier
          tercero por el uso inadecuado del Programa desde su Usuario.
        </p>
        <p>
          3.3. La Empresa se reserva el derecho de dar de baja o inhabilitar al
          Usuario del Programa cuando: i) a solo criterio de la Empresa,
          considere que se hace o ha hecho uso indebido o inconveniente del
          mismo, o que por cualquier otro motivo que la Empresa considere el
          usuario no debe seguir participando del Programa; hipótesis en la cual
          el Usuario perderá la totalidad de los Puntos Canjeables obtenidos
          hasta el momento; ii) el Usuario se niegue a dar o solicite la
          eliminación de alguno de los siguientes datos personales
          proporcionados para el alta como Usuario e imprescindibles para el
          correcto desarrollo del programa: Nombre, Apellido, documento de
          identidad, género con el cual se identifique, fecha de nacimiento,
          email, dirección, departamento, localidad y celular. En esta
          hipótesis, el Usuario que cuente con Puntos Plus generados, contara´
          con un plazo de 30 días para utilizarlos, una vez transcurrido dicho
          plazo se perderá la totalidad de los Puntos Canjeables generados.
        </p>
        <p>
          3.4. El puntaje generado en un Usuario solo podrá ser transferible a
          otros Usuarios exclusivamente a través de la APP de Plus, y también en
          aquellas situaciones que a sola voluntad de la Empresa puedan ser
          consideradas como una excepción.
        </p>
      </div>
      <span className="number-item-title">4. ÁMBITO DE APLICACIÓN</span>
      <div>
        <p>
          4.1. Los Usuarios podrán obtener Puntos Canjeable que se acreditarán a
          su Usuario PLUS en oportunidad de la adquisición de cualquier producto
          en cualquier unidad de negocio de la Empresa, siempre que dicha
          adquisición se abone mediante el pago con efectivo, tarjetas de
          débito, tarjetas de crédito, ticket alimentación electrónico y tarjeta
          Uruguay Social. Las adquisiciones que sean abonadas totalmente
          mediante otras formas de pago, diferentes a las antes mencionadas, no
          acumularán Puntos Canjeables. En caso de adquisiciones que sean
          abonadas parcialmente mediante otras formas de pago, acumularán puntos
          solo por el monto pago en efectivo, tarjetas de débito, tarjetas de
          crédito, ticket alimentación electrónico y tarjeta Uruguay Social.
        </p>
        <p>
          4.2. Los Puntos Canjeables son adjudicados en función del precio de la
          adquisición efectuada, a razón de 1 (un) Punto por cada $100 (cien
          pesos uruguayos) en compras. Toda adquisición por monto inferior al
          indicado no generara´ Puntos Canjeables, no generando tampoco Puntos
          Canjeables la fracción resultante inferior a la suma indicada. Toda
          devolución de mercadería implicara´ la deducción de los Puntos
          Canjeables generados en dicha compra.
        </p>
        <p>
          4.3. En cada operación de compra que el Usuario realice en la Empresa
          se le informara´, conjuntamente con el ticket de compra, la cantidad
          de Puntos Canjeables generados, así como el saldo al momento.
        </p>
      </div>

      <span className="number-item-title">5. PRODUCTOS CANJEABLES.</span>
      <div>
        <p>
          5.1. Los Puntos Canjeables obtenidos por cada Usuario podrán ser
          canjeados por cualquier producto que se encuentre a la venta en
          cualquier unidad de negocio de la Empresa, o en catálogos especiales
          de productos o servicios para utilizarse en otras empresas, por
          acuerdos que se encuentren vigentes al momento del canje.
        </p>
        <p>
          5.2. Para poder realizar un canje, el Usuario deberá tener: a) como
          mínimo 51 Puntos Canjeables acumulados en el saldo disponible y; b)
          completos los datos del Usuario que el Programa “PLUS” a dicho momento
          requiera.
        </p>
        <p>
          5.3. Solicitado el canje por parte del Usuario PLUS, deberá presentar
          su documento que acredite válidamente su identidad, y efectuado el
          mismo, se debitara´ del saldo disponible del Usuario PLUS el
          equivalente al precio del producto adquirido.
        </p>
        <p>
          5.4. Solamente el titular de la Cuenta PLUS podrá solicitar canje, y
          siempre presentando su documento de identidad vigente y en buenas
          condiciones.
        </p>
      </div>

      <span className="number-item-title">6. COMUNICACIONES Y CONSULTAS</span>
      <div>
        <p>
          6.1. El Usuario PLUS puede resolver cualquier consulta que tenga, o
          cualquier comunicación que debiera efectuar con relación al Programa,
          a través del sitio web{' '}
          <a href="www.plus.uy " className="tata-link">
            www.plus.uy{' '}
          </a>{' '}
          o mediante el siguiente número telefónico 08008065.
        </p>
      </div>

      <span className="number-item-title">
        7. USUARIOS PLUS SIN ACTIVIDAD Y VENCIMIENTO DE PUNTOS GENERADOS.
      </span>
      <div>
        <p>
          7.1. Se considera Usuario PLUS sin actividad todo aquel que no
          registre actividad dentro de su Cuenta—ya fuere por adquisición de
          productos o canje de puntos—por un periodo de tres (3) meses corridos
          a contar del último movimiento.
        </p>
        <p>
          7.2. Verificado el transcurso del lapso indicado en el apartado
          anterior, caducarán los Puntos Canjeables obtenidos de pleno derecho,
          y el saldo del Usuario PLUS será de cero (0).
        </p>
      </div>
    </>
  )
}

export default SixthTextSection

function FourthTextSection() {
  return (
    <>
      <span className="number-item-title">
        9. CAMBIOS, DEVOLUCIONES Y DESISTIMIENTOS
      </span>
      <div>
        <p>
          El desistimiento o rescisión del contrato podrá efectivizarse siempre
          que el mismo sea comunicado a TATA, por cualquier medio fehaciente,
          dentro del plazo de siete (7) días hábiles de perfeccionado el
          contrato de compraventa.
        </p>
        <p>
          El proceso a estos efectos se encuentra previsto por las normas en
          materia de “Cambios y devoluciones de artículos adquiridos en la
          tienda online de TATA con o sin desperfectos”, que forma parte de los
          presentes Términos y Condiciones.
        </p>
        <p>
          Ver más información en{' '}
          <a href="www.tata.com.uy" className="tata-link">
            www.tata.com.uy
          </a>
          : <span className="bolder-text-inst">Cambios y devoluciones</span>
        </p>
      </div>
      <span className="number-item-title">10. COSTOS ADICIONALES</span>
      <div>
        <p>
          El comprador deberá abonar los costos de envío del/los artículos
          adquiridos. El precio del envío se facturará conjuntamente con la
          compraventa y de conformidad a la “Información sobre servicios de
          entrega” que forma parte de los presentes Términos y Condiciones.
        </p>
      </div>
      <span className="number-item-title">
        11. OPERATIVIDAD Y FALLAS EN EL SITIO WEB
      </span>
      <div>
        <p>
          TATA no se responsabiliza sobre desperfectos que pudieren sobrevenir
          en el sitio web que impidan su utilización, los que podrán ser por
          diversas causas, del sistema, técnicas, derivadas del servicio de
          internet, pudiéndose suspender el sitio en ocasión de su remodelación.
          En caso que ello ocurra será puesto en conocimiento de los Usuarios en
          la misma página web, por un periodo de tiempo prudencial, antes de
          procederse a la suspensión del mismo. El servicio ofrecido a través de
          este Sitio podrá sufrir suspensiones por razones técnicas o por
          trabajos de mantenimiento, o verse interrumpido o suspendido por
          causas no imputables a TATA como ser fuerza mayor o caso fortuito, sin
          que por ello se incurra en responsabilidad alguna, y sin derecho del
          Cliente de reclamar indemnización o compensación de clase alguna por
          la interrupción o suspensión.
        </p>
        <p>
          El Usuario se compromete a no acceder ni a intentar acceder al Sitio,
          ni usar de ninguno de los Servicios por ningún otro medio que no sea
          la interfaz provista por TATA. Asimismo, se compromete a no
          involucrarse en ninguna actividad que interfiera o que interrumpa o
          que tuviere entidad suficiente para interferir o interrumpir la
          prestación de los servicios del Sitio o los servidores y redes
          conectados al mismo. Está prohibido a los Usuarios violar, vulnerar
          y/o de cualquier forma afectar el normal uso y la seguridad del Sitio
          incluyendo, pero sin limitarse a: a) acceder a datos que no se
          destinan a ese Usuario o ingresar a una computadora o a una cuenta a
          los cuales no esté el Usuario autorizado para acceder; b) tratar de
          sondear, analizar o probar la vulnerabilidad de un sistema o una red o
          romper medidas de seguridad o autenticación sin la debida
          autorización; c) intentar interferir con el servicio a cualquier
          Usuario, computadora o red. Las violaciones de la seguridad del
          sistema o de la red pueden dar lugar a responsabilidad civil o penal.
          El Usuario y/o el navegante ocasional del Sitio o de la App aceptan y
          reconocen expresamente que cualquier hecho, acto u omisión vinculadas
          con lo referido anteriormente y que tengan entidad para afectar la
          seguridad y/o el normal desarrollo del Sitio tienen entidad suficiente
          para causar a TATA daños y perjuicios incluyendo sin limitar: lucro
          cesante por merma en las ventas, afectación de sus marcas, imagen
          comercial, pérdida de clientela, entre otros.
        </p>
      </div>
      <span className="number-item-title">
        12. DERECHOS DE PROPIEDAD INTELECTUAL Y DE PROPIEDAD INDUSTRIAL
      </span>
      <div>
        <p>
          El diseño de la presente Página web, así como todas las imágenes,
          productos, códigos fuente, logos, marcas y demás, en ella publicadas,
          son de la titularidad de TA-TA S.A. por lo que su uso indebido,
          reproducción, distribución, comunicación pública o con fines
          comerciales, transformación o cualquier otra actividad sin
          autorización expresa de su titular queda totalmente prohibida y será
          pasible de responsabilidad.
        </p>
      </div>
      <span className="number-item-title">13. ÁMBITO DE APLICACIÓN</span>
      <div>
        <p>
          Las condiciones y términos que se enumeran en el presente contrato son
          únicamente aplicables a las compras y a la navegación que se
          verifiquen exclusivamente a través de este sitio, quedando fuera de
          las mismas las compras realizadas en TATA a través de otros canales de
          venta, y siempre, dentro del territorio Nacional.
        </p>
      </div>
      <span className="number-item-title">
        14. NORMATIVA APLICABLE - JUZGADOS COMPETENTES
      </span>
      <div>
        <p>
          La normativa aplicable será la de la República Oriental del Uruguay,
          tanto en materia de derecho sustancial como procesal y los Tribunales
          Competentes serán los de la ciudad de Montevideo, República Oriental
          del Uruguay.
        </p>
      </div>
    </>
  )
}

export default FourthTextSection

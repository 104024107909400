/* eslint-disable react/no-unescaped-entities */
function SecondTextSection() {
  return (
    <>
      <span className="number-item-title">1. CAPACIDAD</span>
      <div>
        <p>
          Las compras sólo podrán efectuarse por personas con capacidad legal
          para contratar. Los actos que incapaces realicen en el Sitio serán
          responsabilidad de sus padres, tutores o curadores, y por tanto se
          considerarán realizados por éstos en ejercicio de la representación
          legal con la que cuentan. En caso que se registre como Usuario una
          persona jurídica, quien la representa, deberá tener capacidad legal
          para contratar a nombre de la misma y obligarla en los términos de
          este acuerdo.
        </p>
      </div>
      <span className="number-item-title">2. REGISTRO</span>
      <div>
        <p>
          Es obligatorio completar el formulario de registro en todos sus campos
          con datos válidos para la adquisición de productos y servicios
          ofrecidos en este sitio. El Usuario deberá completarlo con su
          información personal de manera exacta, precisa y verdadera
          ("Identificación") y asume el compromiso de actualizar sus Datos
          Personales conforme resulte necesario. TATA podrá utilizar diversos
          medios para identificar a sus Usuarios, pero TATA NO se responsabiliza
          por la certeza de los Datos Personales provistos por sus Usuarios. Los
          Usuarios garantizan y responden, en cualquier caso, de la veracidad,
          exactitud, vigencia y autenticidad de los Datos Personales ingresados.
        </p>
        <p>
          El Usuario registrado o visitante del sitio será responsable de sus
          Datos, usuario y contraseña, y de los eventuales perjuicios que padece
          por sí, o que causa a terceros o a TATA, a consecuencia de informar o
          tratar erróneamente sus datos personales.
        </p>
        <p>
          TATA se reserva el derecho de solicitar cualquier comprobante y/o dato
          adicional a efectos de corroborar los Datos Personales, así como de
          suspender temporal o definitivamente a aquellos Usuarios cuyos datos
          no hayan podido ser confirmados. En estos casos de inhabilitación, se
          dará de baja la compra efectuada, sin que ello genere algún derecho a
          resarcimiento o responsabilidad por parte de TATA S.A.
        </p>
        <p>
          El Usuario será responsable por todas las operaciones efectuadas en su
          Cuenta, sea el uso por sí y/o por terceros, pues el acceso a la misma
          está restringido al ingreso y uso de su correo electrónico, salvo por
          aquellas que sean realizadas por culpa o negligencia exclusiva de la
          Empresa. El Usuario se compromete a notificar a TATA en forma
          inmediata y por cualquiera de los medios, cualquier uso no autorizado
          de su Cuenta, así como el ingreso por terceros no autorizados a la
          misma. Se aclara que está prohibida la venta, cesión o transferencia
          de la Cuenta bajo ningún título.
        </p>
      </div>

      <span className="number-item-title">
        3. PRIVACIDAD Y DATOS PERSONALES
      </span>
      <div>
        <p>
          Los datos personales proporcionados serán tratados por TA TA S.A.
          según lo establecido en la Ley Nº 18.331 del 11 de agosto de 2008 y su
          decreto reglamentario Nº 414/2009 del 31 de agosto de 2009,
          modificativas y concordantes. Por mayor información, ver Política de
          Privacidad de la Web.
        </p>
        <p>
          TA TA S.A. podrá utilizar cookies cuando se utilice la página web. No
          obstante, el usuario podrá configurar su navegador para ser avisado de
          la recepción de las cookies e impedir en caso de considerarlo
          adecuado, su instalación.
        </p>
      </div>

      <span className="number-item-title">
        4. USO Y ACEPTACIÓN DE CONTRATOS A TRAVÉS DEL SITIO. GENERALIDADES
      </span>
      <div>
        <p>
          En los contratos ofrecidos por medio de este sitio, TATA informará, de
          manera inequívoca y fácilmente accesible, los pasos que deben seguirse
          para celebrarlos, e informará, cuando corresponda, si el documento
          electrónico en que se formalice el contrato será archivado y si éste
          será accesible al consumidor.
        </p>
        <p>
          El sólo hecho de seguir los pasos para realizar una compra, implica
          aceptación expresa de los presentes Términos y Condiciones por parte
          del Usuario, y que la empresa oferente efectivamente ha dado
          cumplimiento a las condiciones contenidas en este numeral.
        </p>
        <p>
          Las fotografías o ilustraciones publicadas en el Sitio lo son solo a
          efectos ilustrativos, y la información de los productos podrá variar
          en caso de errores involuntarios, sin que ello implique
          responsabilidad alguna de TATA.
        </p>
        <p>
          Los Clientes aceptan que los precios de los productos en la página Web
          de TATA puedan variar respecto de los colocados de los locales físicos
          de TATA, y que, en base a ello, no podrán basar ningún reclamo por
          diferencia de precios. Asimismo, TATA podrá aplicar promociones sobre
          determinados productos exclusivamente para venta web, o exclusivamente
          para venta en los locales, sin obligación de tener que extender dichas
          promociones a otras vías de venta no comunicadas en la información de
          la promoción. Asimismo, TATA puede establecer mínimos o máximos en la
          cantidad, volumen o precio de los productos, según sea el caso.
        </p>
      </div>
      <span className="number-item-title">
        5. FORMACIÓN DEL CONSENTIMIENTO LOS CONTRATOS CELEBRADOS EN ESTE SITIO Y
        VALIDACIÓN DE LOS MISMOS
      </span>
      <div>
        <p>
          A través de este sitio web TATA realizará ofertas de bienes, que
          podrán ser aceptadas, por vía electrónica o telefónica, y utilizando
          los mecanismos que el mismo sitio ofrece para ello. Toda aceptación de
          oferta quedará sujeta a la condición suspensiva de que la empresa
          oferente (TATA) valide la transacción. En consecuencia, para toda
          operación que se efectúe en este sitio, la confirmación y/o validación
          o verificación por parte de TATA, será requisito para la formación del
          consentimiento. Para validar la transacción TATA deberá verificar: a)
          Que dispone, en el momento de la aceptación de oferta, de las especies
          y cantidades en stock. b) Que valida y acepta el medio de pago
          ofrecido por el Usuario. c) Que los datos registrados por el cliente
          en el sitio coinciden con los proporcionados al efectuar su aceptación
          de oferta. d) Que la publicación de la oferta no adolece de ningún
          error (incluyendo sin limitar, error manifiesto en el precio
          publicado, o en las características esenciales del producto).
        </p>
        <p>
          La recepción de correos electrónicos confirmando la recepción del
          pedido y/o informando el comienzo de preparación del pedido, no
          significarán validación de la transacción por parte de TATA. Son
          correos de envío automático, por lo que cualquier transacción podrá
          ser no aceptada o no validada por TATA aún con posterioridad al envío
          de los correos antedichos, una vez constatados todos los extremos
          estipulados. La validación se configurará cuando el Usuario reciba un
          correo electrónico que indique que el Pedido ha sido FACTURADO.
        </p>
        <p>
          El Usuario acepta que TATA, luego de recibir un pedido, por distintas
          razones puede no estar en condiciones de aceptarlo total o
          parcialmente y deba por ello cancelar o excluir del mismo algunos
          productos pedidos por el Usuario. Esto puede ocurrir cuando sucedan
          determinadas situaciones, incluyendo, sin limitar: debido a
          limitaciones en las cantidades disponibles para la compra; faltantes
          de mercadería, falta de autorización a la operación por parte de la
          entidad emisora o administradora de su tarjeta de crédito;
          inexactitudes o errores en el producto o información; o problemas
          identificados por el departamento de prevención de fraude de crédito y
          de precios.
        </p>
        <p>
          El Sitio sólo se puede utilizar con fines lícitos de oferta de
          productos a los posibles Clientes, y la adquisición por parte de ellos
          de los productos ofrecidos a través de este medio. Los Usuarios se
          obligan a mantener indemne a TATA de cualquier reclamación
          extrajudicial, judicial o administrativa que pueda plantear cualquier
          tercero, por el uso indebido del Sitio por parte del Cliente y de la
          actividad que éste desarrolle en el mismo. Asimismo, siendo el
          propósito del Sitio permitir a los Usuarios realizar compras online,
          sin concurrir personalmente a las sucursales, TATA se esforzará por
          proporcionar información precisa sobre esos precios, sin perjuicio de
          lo cual, pueden ocurrir errores. En el caso de que un artículo figure
          en las listas del Sitio a un precio incorrecto, TATA tendrá el
          derecho, de corregir la orden facturando el precio vigente al momento
          del armado del pedido o bien, de anular el pedido reintegrando al
          Usuario los importes abonados. En este caso, se dará aviso del error
          al Usuario, y con él, la posibilidad de cancelar o modificar la compra
          del producto facturado u ofrecido de forma incorrecta.
        </p>
        <p>
          A efectos de comunicar al Usuario o consumidor acerca de la validación
          del pedido, la empresa oferente deberá enviar una confirmación escrita
          a la misma dirección electrónica que haya registrado el Usuario
          aceptante de la oferta, o por cualquier medio de comunicación que
          garantice el debido y oportuno conocimiento del consumidor. El
          consentimiento se entenderá formado desde el momento en que se envía
          esta confirmación escrita al Usuario. No se considerará “validación” o
          confirmación definitiva, el correo de confirmación de recepción de
          pedido o de comienzo de preparación del mismo. La validación se
          configurará cuando el Usuario reciba un correo electrónico que indique
          que el Pedido ha sido FACTURADO.
        </p>
      </div>
    </>
  )
}

export default SecondTextSection

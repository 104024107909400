function FifthTextSection() {
  return (
    <>
      <div className="otros-container">
        <div className="section-title">
          <span className="section-title-text">OTROS</span>
        </div>
        <div>
          <p>
            Al usar el presente Sitio, deberá tenerse en consideración que, en
            ocasiones, los fabricantes pueden alterar sus empaques y etiquetas,
            de manera que el verdadero embalaje del producto y materiales
            adjuntos pueden contener información diferente de la que se exhibe.
            TATA sugiere al Usuario leer las etiquetas, advertencias e
            instrucciones antes de adquirir, utilizar o en cualquier forma
            consumir un producto. Para obtener información adicional acerca de
            un producto, deberá consultar con el fabricante, distribuidor o
            importador.
          </p>
          <p>
            El Usuario puede rescindir este contrato en cualquier momento,
            siempre que lo haga para el futuro evitando el uso de este Sitio y
            renunciando a su registro siguiendo el procedimiento especialmente
            previsto para ello. TATA podrá rescindir este contrato en cualquier
            momento siendo válida la notificación de tal rescisión a los
            domicilios reales o electrónicos que el Usuario tuviere registrados
            en el Sitio. Asimismo, TATA podrá cancelar sin previo aviso la
            condición de Usuario, y en consecuencia denegar el acceso a los
            Servicios del Sitio o a comprar por esta vía, si el Usuario no
            cumpliera con cualquier término o disposición de los presentes
            Términos y Condiciones, y/o, configure alguno de los siguiente
            extremos: hace uso indebido de un beneficio que corresponde a otro
            usuario, hace uso indebido del sitio, entre otros.
          </p>
          <p>
            TATA podrá en cualquier momento, temporal o permanentemente dar de
            baja este Sitio.
          </p>
        </div>
      </div>
    </>
  )
}

export default FifthTextSection

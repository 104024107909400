function ThirdTextSection() {
  return (
    <>
      <span className="number-item-title">6. CONFIRMACIÓN DE PEDIDO</span>
      <div>
        <p>
          El Cliente confirmará el pedido realizado haciendo “click” en el botón
          FINALIZAR dentro del proceso de pago. A partir de esta confirmación el
          pedido es firme y definitivo respecto del Cliente, quedará registrado
          de forma automática y TATA procederá a su validación, y posterior
          entrega, si correspondiere (si se cumpliere con lo dispuesto en el
          punto 5.). El registro automático del pedido realizado tiene valor de
          prueba en cuanto a su naturaleza, contenido y fecha. La confirmación
          del pedido por parte del Cliente conlleva la aceptación de la oferta y
          la previa lectura y aceptación de los presentes Términos y
          Condiciones, condiciones particulares, en su caso, y aviso legal. Una
          vez finalizado el proceso de compra, el sistema confirmará al Cliente
          la recepción del pedido por medio del envió de e-mail a la dirección
          de correo electrónico consignada al registrarse (Si el Usuario no
          recibe tal e-mail, deberá contactarse con el Atención al Cliente de
          TATA a fin de constatar que no hubo errores de registro al ingresar la
          casilla de correo). Sin embargo, la recepción de este e-mail no
          implica la validación de la transacción por parte de TATA, la que
          estará sujeta al proceso indicado en la punto 5 del presente.
        </p>
      </div>
      <span className="number-item-title">7. MEDIOS DE PAGO</span>
      <div>
        <p>
          TATA informará, en todos los casos, el precio de sus productos
          incluido los impuestos, la moneda, el tipo de cambio si
          correspondiere, así como los medios de pago y financiación. Los pagos
          podrán efectuarse con tarjetas de crédito Master Card, Líder, Diners
          (a través de Mercado Pago), crédito y débito Visa (a través de Siempre
          Pago), y Oca, Visa, Master, Cabal, Pass card, Creditel, Líder, Diners,
          Créditos Directos, Amex y Anda (a través de POS con el medio de pago
          Cobro contra entrega). Dichas entidades financieras regularán las
          condiciones y términos de pago. TATA no administra los datos
          transaccionales de cada Cliente, siendo las mismas administradas por
          los servidores de cada entidad crediticia.
        </p>
        <p>
          Se deja constancia que TATA no se hace responsable por los reintegros
          que deban efectuar los bancos o las instituciones financieras emisores
          de tarjetas de crédito, respecto de las promociones vigentes, siendo
          dichas empresas, las únicas responsables de efectuar en tiempo y forma
          el reintegro correspondiente.
        </p>
      </div>
      <span className="number-item-title">8. ENTREGA DE PEDIDO</span>
      <div>
        <p>
          El pedido será entregado al Cliente o a la persona autorizada por éste
          en oportunidad de efectuar la transacción, en la dirección indicada,
          siempre que se encuentre en zonas habilitadas por TATA para realizar
          entregas a domicilio. El Cliente o autorizado, en oportunidad de la
          recepción del o los artículos adquiridos, firmará el recibo de entrega
          correspondiente, consignando su nombre y documento de identidad, así
          como declarará haber examinado los mismos, por lo que implica su
          conformidad con los productos recibidos, tanto en cuanto a cantidad
          como a calidad y estado en que se encuentran, todo de conformidad a la
          “información sobre servicios de entrega de{' '}
          <a href="www.tata.com.uy" className="tata-link">
            www.tata.com.uy
          </a>
          ”, que forma parte de los presentes Términos y Condiciones.
        </p>
        <p>
          Si el Cliente no pudiera estar en su domicilio el día y turno horario
          elegidos para efectuar la recepción, deberá ponerse en contacto con el
          Servicio de Atención al Cliente para acordar otro día y/o turno de
          entrega.
        </p>
        <p>
          TATA informará, previo a efectuar la compraventa, el plazo para la
          entrega, el que se contará a partir de la confirmación de la
          transacción, sin perjuicio de demoras que pudieran originarse por
          causas ajenas a la voluntad de TATA, procediendo en todos los casos a
          informar al Cliente de esa situación, no siendo pasible de
          responsabilidad de tipo alguno por tal motivo.
        </p>

        <p>
          TATA se reserva el derecho de no enviar a domicilio los productos que
          a su juicio requieren mantenerse en ambiente refrigerado, como:
          Congelados, Jugos naturales, Frutas, Verduras, Rotisería, Carnicería y
          Pescadería así como cualquier otro a criterio de TATA.
        </p>
        <p>
          Ver más información en{' '}
          <a href="www.tata.com.uy" className="tata-link">
            www.tata.com.uy
          </a>
          : <span className="bolder-text-inst">Cómo comprar</span>
        </p>
      </div>
    </>
  )
}

export default ThirdTextSection

function SeventhTextSection() {
  return (
    <>
      <span className="number-item-title">
        8. MODIFICACION DE LAS CONDICIONES GENERALES DE USO.
      </span>
      <div>
        <p>
          8.1. La Empresa se reserva el derecho de modificar total o
          parcialmente las presentes bases y condiciones en cualquier momento.
          Las modificaciones a las Bases y Condiciones se notificaran al
          ingresar a la pagina web{' '}
          <a href="www.plus.uy " className="tata-link">
            www.plus.uy{' '}
          </a>
          mediante una ventana en modo Pop-up y/o a través de cualquier otra
          forma que la Empresa pueda determinar, y los Usuarios tendrán un plazo
          de 30 días para aceptarlas o darse de baja del programa, en caso de no
          pronunciarse, al pasar los 30 días se tomarán por aceptadas.
        </p>
        <p>
          8.2. Las modificaciones que la Empresa realice tendrán efecto a partir
          de los 30 días de notificado el cambio de las mismas. Se aplicarán a
          todos los Usuarios PLUS activos a la fecha de la modificación y las
          personas que posterior a dicha modificación sean Usuarios PLUS.
        </p>
        <p>
          8.3. La modificación de las presentes Bases y Condiciones será
          publicada en el sitio{' '}
          <a href="www.plus.uy " className="tata-link">
            www.plus.uy{' '}
          </a>{' '}
          dentro del Programa PLUS.
        </p>
        <p>
          8.4. La Empresa no será responsable, en ningún caso, por las
          modificaciones totales o parciales de las Bases y Condiciones del
          presente Programa.
        </p>
      </div>

      <span className="number-item-title">9. FINALIZACIÓN</span>
      <div>
        <p>
          9.1. La Empresa se reserva el derecho de finalizar el Programa en
          cualquier momento mediante previo aviso de un mes a los Usuarios PLUS
          en la web{' '}
          <a href="www.plus.uy " className="tata-link">
            www.plus.uy
          </a>
          .
        </p>
      </div>

      <span className="number-item-title">10. RESPONSABILIDADES</span>
      <div>
        <p>
          10.1. La Empresa no se hace responsable de la utilización deficiente o
          fraudulenta por parte de los Usuarios PLUS ni de terceros del presente
          Programa.
        </p>
        <p>
          10.2. El Usuario será responsable de los daños y perjuicios que fueran
          consecuencia de actos u omisiones propios del Usuario.
        </p>
      </div>

      <span className="number-item-title">11. LEGISLACIÓN</span>
      <div>
        <p>
          11.1. Las Bases y Condiciones del Programa se regirán, interpretarán,
          cumplirán y ejecutarán conforme a las leyes y normas de la República
          Oriental del Uruguay, en todo lo que expresamente no se haya
          modificado en la presente, conforme a la autonomía de la voluntad de
          los contratantes.
        </p>
      </div>
      <span className="number-item-title">12. CONSIDERACIONES FINALES.</span>
      <div>
        <p>
          12.1. 1. La eventual nulidad de alguna de las cláusulas del presente
          no importara´ la nulidad de las restantes.
        </p>
        <p>
          12.2. El hecho que la Empresa omita exigir el cumplimiento de un
          término o condición en particular del Programa o más de uno de ellos,
          no constituye una renuncia a dicho término o condición para la
          Empresa.
        </p>
        <p>
          12.3. La participación en el Programa PLUS implica la aceptación de
          todas las condiciones establecidas en el presente, las que se
          consideran conocidas por todos los Usuarios desde su Adhesión al
          Programa.
        </p>
        <p>
          12.4. La Empresa se reserva el derecho de modificar las presentes
          Bases y Condiciones sin previo aviso.
        </p>
        <p>
          12.5. La Empresa se reserva el derecho de interpretar estas bases en
          caso de duda o discrepancia como así también de modificar las mismas
          ante cualquier imprevisto o eventualidad, sin incurrir por ello en
          responsabilidad de clase alguna.
        </p>
        <p>
          12.6. En caso de problemas informáticos, donde por ejemplo se otorguen
          puntos de manera incorrecta, la Empresa será la encargada de revertir
          ese error, siendo su decisión no discutible.
        </p>
        <p>
          12.7. En caso de fallecimiento del Usuario, no existiendo al momento
          del suceso Usuario Adicional declarado, los puntos acumulados y/o los
          beneficios canjeados quedarán a disposición de la Empresa. En caso
          que, al momento del fallecimiento del Usuario, la cuenta tenga un
          Usuario Adicional declarado, este podrá solicitar el cambio de
          Titularidad de los puntos plus previa presentación de la documentación
          que acredite el fallecimiento del Usuario y firma de la carta
          correspondiente de cambio de titularidad. Para realizar esta gestión,
          el Usuario Adicional contará con plazo de 180 días..
        </p>
      </div>
      <span className="number-item-title">
        13. ACTUALIZACIÓN DE DATOS DEL USUARIO
      </span>
      <div>
        <p>
          13.1. El Usuario deberá comunicar en forma inmediata y
          fehaciente--mediante las vías aceptadas en las Bases y Condiciones--a
          la Empresa, de cualquier cambio que se produzca en sus datos
          personales. Si así no lo hiciese será causal suficiente para poder ser
          dado de baja del Programa PLUS.
        </p>
        <p>
          13.2. Cualquier comunicación cursada por la Empresa a un Usuario se
          considerara´ notificada si fuere remitida al domicilio y/o
          telefónicamente y/o a través de correo electrónico a su dirección de
          correo obrante en los registros de la Empresa.
        </p>
        <p>
          13.3. La Empresa no será responsable, en ningún caso, de reclamos que
          tuviere como fundamento la falta de información del Usuario o el
          manejo por parte del Usuario de fuentes de información
          desactualizadas.
        </p>
      </div>

      <span className="number-item-title">14. RENUNCIA</span>
      <div>
        <p>
          14.1. El Usuario podrá poner fin a su participación en el Programa en
          cualquier momento completando en cualquier unidad de negocio de la
          Empresa la solicitud de baja del servicio, en adelante “Solicitud de
          Baja” o comunicándose al correo electrónico
          consultas.basededatos@tata.com.uy. Una vez el Usuario sea dado de baja
          no podrá disponer del saldo de Puntos Canjeables que tuviere
          acumulado, quedando este en 0 (cero).
        </p>
      </div>
      <span className="number-item-title">
        15. PROTECCIÓN DE DATOS Y CESIÓN DE DERECHO DE USO DE LA IMAGEN
      </span>

      <div>
        <p>
          15.1. Los datos personales proporcionados, serán tratados por TA TA
          S.A. según lo establecido en la Ley Nº 18.331 del 11 de agosto de 2008
          y su decreto reglamentario Nº 414/2009 del 31 de agosto de 2009,
          modificativas y concordantes, y de acuerdo a lo establecido en
          “Políticas de Privacidad de Plus”.
        </p>
      </div>
    </>
  )
}

export default SeventhTextSection

import type { SiteSiteMetadata } from '@generated/graphql'
import { graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useSession } from 'src/sdk/session'
import StockAvailable from 'src/components/common/StockAvailable/StockAvailable'
import Newsletter from 'src/components/sections/Newsletter'
import '../../styles/pages/terminos-y-condiciones.scss'
import FirstTextSection from 'src/components/terminosCondiciones/FirstTextSection'
import SecondTextSection from 'src/components/terminosCondiciones/SecondTextSection'
import ThirdTextSection from 'src/components/terminosCondiciones/ThirdTextSection'
import FourthTextSection from 'src/components/terminosCondiciones/FourthTextSection'
import FifthTextSection from 'src/components/terminosCondiciones/FifthTextSection'
import SixthTextSection from 'src/components/terminosCondiciones/SixthTextSection'
import SeventhTextSection from 'src/components/terminosCondiciones/SeventhTextSection'

interface Props {
  data: TAllCmsInstitutional
  location: {
    pathname: string
  }
}

interface TAllCmsInstitutional {
  allCmsInstitutionalPage: TEdges
  site: {
    siteMetadata: SiteSiteMetadata
  }
}

type TEdges = {
  edges: Array<{ node: { sections: Array<{ data: { iframe: string } }> } }>
}

const Page = ({ location, data: { site } }: Props) => {
  const { locale } = useSession()
  const title = site?.siteMetadata?.title ?? ''

  return (
    <>
      <GatsbySeo
        title={title}
        titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
        description={site?.siteMetadata?.description ?? ''}
        canonical={location.pathname}
        language={locale}
        openGraph={{
          type: 'website',
          title,
          description: site?.siteMetadata?.description ?? '',
        }}
      />
      <div style={{ paddingTop: '16px' }} />
      <section className="terminos-content">
        <div className="content-header">
          <h2 className="page-title">Términos y Condiciones Generales.</h2>
          <div className="black-border" />
        </div>
        <div className="section-title">
          <span className="section-title-text">
            Términos y Condiciones Generales.
          </span>
          <span className="section-subtitle-text">
            TÉRMINOS Y CONDICIONES PARA LA VENTA EN TATA ONLINE
          </span>
        </div>
        <div className="download-container">
          <a href="https://tatauy.vteximg.com.br/arquivos/terminos-y-condiciones.pdf?v=637763840869430000">
            <img
              src="https://tatauyqa.vtexassets.com/arquivos/descargar icon.png"
              alt="descargar pagina"
            />
          </a>
        </div>
        <FirstTextSection />
        <div className="numbered-items">
          <SecondTextSection />

          <ThirdTextSection />
          <FourthTextSection />
        </div>
        <FifthTextSection />
        <div className="anexo-container">
          <SixthTextSection />

          <SeventhTextSection />
        </div>
      </section>
      <div className="newsletter-container-inst-page">
        <StockAvailable icon />
        <Newsletter
          title="Recibí promociones y novedades"
          subtitle="Ingresá tu correo electrónico"
        />{' '}
      </div>
    </>
  )
}

export const query = graphql`
  query LandingPageTerminosQuery {
    site {
      siteMetadata {
        titleTemplate
        title
        description
        siteUrl
      }
    }
    allCmsInstitutionalPage(
      filter: {
        seo: {
          siteMetadataWithSlug: { slug: { eq: "/lp/terminos-y-condiciones" } }
        }
      }
    ) {
      edges {
        node {
          seo {
            siteMetadataWithSlug {
              slug
            }
          }
          name
          sections {
            data
          }
        }
      }
    }
  }
`

export default Page

export { Head } from 'src/components/common/Head'
